<template>
  <div class="pageBox">
    <div class="content-block">
      <!-- dataState:1-未完善 2-已完善 3-冻结 4-待审核 5-未通过 -->
      <div v-if="accountInfo.dataState==1" class="infoStatusBox text-center">
        <p class="msg">您的账号信息还未完善，请先完善资料</p>
        <router-link  to="/account/perfect/info">
          <div class="blackBtn bgBlack margin-t30">完善资料</div>
        </router-link>
      </div>
      <div v-if="accountInfo.dataState==3" class="infoStatusBox text-center">
        <p class="msg">您的账号信息已冻结，无法进行操作，如有问题请通过微信公众号“移动智能终端生态联盟”反馈</p>
      </div>
      <div v-if="accountInfo.dataState==4" class="infoStatusBox text-center">
        <p class="msg">您的账号信息正在审核中，暂时无法进行发布应用等操作，请耐心等待审核结果</p>
        <router-link  to="/account/perfect/info">
          <div class="blackBtn bgBlack margin-t30">查看资料</div>
        </router-link>
      </div>
      <div v-if="accountInfo.dataState==5" class="infoStatusBox text-center">
        <svg class="errorIcon" aria-hidden="true">
          <use xlink:href="#iconguanbi1" />
        </svg>
        <p class="msg errorMsg">您的账号信息审核不通过，请编辑后重新提交</p>
        <div class="errorBox">原因:{{reason}}</div>
        <router-link  to="/account/perfect/info">
          <div class="blackBtn margin-t30">编辑资料</div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { passWord } from "@/utils/RegExp.js";
import utils from "@/utils/util.js";

export default {
  created() {
    //去后台拿账户信息，更新本地状态
    this.$store.dispatch("accountStore/updateAccountInfo", {}).then(res => {
      if (res.code == 0 && res.data) {
        //dataState:1-未完善 2-已完善 3-冻结 4-待审核 5-未通过
        if (res.data.dataState == 2) {
          this.$router.push("/");
        } else if (res.data.dataState == 5) {
          //去后台拿用户资料，更新本地状态
          this.$store.dispatch("accountStore/updateUserData", {}).then(res => {
            if (res.code == 0) {
              this.reason = res.data.reason; //拿审核错误信息
            }
          });
        }
      }
    });
  },
  data() {
    return {
      reason: ""
    };
  },
  computed: {
    accountInfo() {
      return this.$store.getters["accountStore/accountInfo"];
    }
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.pageBox {
  .content-block {
    padding: 50px 0;
    .infoStatusBox {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 20px 0;
      padding: 100px 0;
      width: 100%;
      min-height: 400px;
      background: #fff;
      .errorIcon {
        width: 80px;
        height: 80px;
      }
      .msg {
        font-size: 24px;
        color: rgba(51, 51, 51, 1);
        font-weight: bold;
      }
      .errorMsg {
        margin: 50px 0 30px 0;
      }
      .errorBox {
        display: flex;
        box-sizing: border-box;
        margin: 20px auto;
        width: 624px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(102, 102, 102, 1);
        line-height: 36px;
        text-align: left;
        word-break: break-all;
      }
      .blackBtn {
        width: 225px;
      }
    }
  }
}
</style>